<template>
  <ion-page>
    <ion-content class="ion-padding">
      <UserLoggedCard v-if="isLogged && canShowAlreadyLoggedCard" />
      <div
        v-else
        class="main-content tablet-centered"
      >
        <div
          class="
            is-flex
            ion-align-items-center ion-justify-content-center
            is-flex-direction-column
          "
        >
          <h2 class="title fw-600 mb-3">Medicast</h2>

          <ion-img
            src="./assets/logo.png"
            style="height: 100px"
          />

          <form
            class="form w-100"
            @submit.prevent="submitRegister"
          >
            <AppInput
              autocomplete="email"
              :value="email"
              class="w-100"
              :icon="mailOutline"
              type="email"
              inputmode="email"
              :placeholder="$t('login.emailLabel')"
              @input:update="email = $event"
            />

            <AppInput
              :value="password"
              class="w-100 mt-3"
              :icon="lockClosedOutline"
              autocomplete="new-password"
              :placeholder="$t('login.passwordLabel')"
              :errors="passwordErrors"
              :errors-all="true"
              type="password"
              @blur="validatePasswordRules"
              @input:update="password = $event"
            />

            <div class="ion-margin-top tags">
              <app-tag
                v-for="rule in passwordRules"
                :key="rule.label"
                :type="rule.valid ? 'is-success': 'is-incorrect'"
              >{{ rule.label }}</app-tag>

              <app-tag
                :type="passwordMatch ? 'is-success': 'is-incorrect'"
              > {{ $t('passwordRepeat.passwordMatch')}} </app-tag>
            </div>

            <AppInput
              :value="passwordRepeat"
              class="w-100 mt-3"
              :icon="lockClosedOutline"
              :placeholder="$t('passwordRepeat.passwordRepeat')"
              autocomplete="new-password"
              type="password"
              @input:update="passwordRepeat = $event"
            />

            <ion-item
              lines="none"
              class="checkbox-container mt-2 --transparent"
            >
              <ion-label>
                {{ $t('register.termsConditionsText') }}
                <a
                  href="#"
                  @click.prevent="openInBrowser('/algemene-voorwaarden')"
                >
                  {{ $t('register.termsConditionsText') }}
                </a>
              </ion-label>
              <ion-checkbox
                slot="start"
                @update:modelValue="termsConditionsAccepted = $event"
                :model-value="termsConditionsAccepted"
                mode="md"
                class="checkbox"
              />
            </ion-item>

            <div class="submit-container">
              <ion-button
                expand="block"
                shape="round"
                mode="ios"
                :color="!termsConditionsAccepted || !passwordValid ? 'medium': undefined"
                type="submit"
                class="fw-500 fz-18"
                @input:enter="submitRegister"
              >
                {{ $t('commonKeys.register') }}
              </ion-button>
            </div>

            <div class="mt-2 is-flex ion-justify-content-center">
              <router-link
                to="/login"
                class="simple-link fw-500"
              >
                {{ $t('register.alreadyHaveAccountText') }}
              </router-link>
            </div>
          </form>
        </div>
      </div>

      <IonLoading
        :is-open="loading"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonImg,
  IonLoading, onIonViewDidEnter, modalController, onIonViewDidLeave,
} from '@ionic/vue';

import { mailOutline, lockClosedOutline } from 'ionicons/icons';

import { useStore } from "vuex";
import { ref } from '@vue/reactivity';
import { useReCaptcha  } from 'vue-recaptcha-v3';
import { useI18n } from 'vue-i18n/index';

import AppInput from '@/components/common/AppInput.vue';
import useRequestToast from "@/composables/useRequestToast";
import UserLoggedCard from "@/components/common/UserLoggedCard";
import useAppStorage from "@/composables/useAppStorage";

import { useRouter } from 'vue-router';
import { computed } from "vue";

import usePushNotifications from "@/composables/usePushNotifications";
import useFirebase from "@/composables/useFirebase8";
import useInAppBrowser from "@/composables/useInAppBrowser";
import {DEEP_LINK_REDIRECT_STORAGE_KEY, NOT_FOUNT_REDIRECT_STORAGE_KEY} from "@/config/constants";
import auth from '@/plugins/auth';
import VerifyModal from "@/components/login/VerifyModal";
import AppTag from "@/components/common/AppTag";
import usePasswordValidation from "@/composables/usePasswordValidation";

export default {
  name: 'SignUp',
  components: {
    AppInput,
    IonContent,
    IonPage,
    IonButton,
    IonItem,
    IonLabel,
    IonImg,
    IonCheckbox,
    IonLoading,
    UserLoggedCard,
    AppTag,
  },
  setup() {
    const { openUrl } = useInAppBrowser();
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const { getKey, removeKey } = useAppStorage();
    const { initDB: fireDB } = useFirebase();
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    const { openErrorToast, openToast } = useRequestToast();
    const { initNotifications } = usePushNotifications();

    const { password, errors: passwordErrors, validateRules: validatePasswordRules, rules: passwordRules, valid: rulesValid } = usePasswordValidation();

    const passwordRepeat = ref(null);
    const termsConditionsAccepted = ref(false);
    const canShowAlreadyLoggedCard = ref(true);
    const loading = ref(false);

    const isLogged = computed(() => store.getters['authentication/isLogged']);
    const email = computed({
      get() { return store.getters['authentication/getEmailState']; },
      set(value) { store.commit('authentication/setEmailState', value); },
    });

    const passwordMatch = computed(() => {
      return password.value && (password.value === passwordRepeat.value);
    });

    const passwordValid = computed(() => !!(passwordMatch.value && rulesValid.value));


    const getRecaptchaToken = async () => {
      await recaptchaLoaded();
      return await executeRecaptcha('login');
    };

    const openInBrowser = (url) => {
      openUrl(`${process.env.VUE_APP_URL}${url}`);
    };

    const submitRegister = async () => {
      auth.drivers.router.isRedirect = false;

      if (!termsConditionsAccepted.value) {
        openToast({
          message: t('register.termsNotAccepted'),
          position: 'top',
          color: 'danger',
          duration: 2000,
        });
        return;
      }

      if (!passwordValid.value) {
        validatePasswordRules();
        return;
      }

      loading.value = true;

      let recaptcha;
      let redirect;

      try {
        recaptcha = await getRecaptchaToken();
        redirect = await getKey(DEEP_LINK_REDIRECT_STORAGE_KEY);

        if (!redirect) {
          redirect = await getKey(NOT_FOUNT_REDIRECT_STORAGE_KEY);
        }

        // eslint-disable-next-line no-empty
      } catch (e) {}

      canShowAlreadyLoggedCard.value = false;
      store.dispatch('authentication/register', {
        email: email.value,
        password: password.value,
        password_confirmation: passwordRepeat.value,
        recaptcha,
      })
        .then(() => {
          store.dispatch('authentication/resetOnLoginLogout');
          initNotifications(true);
          fireDB().goOnline();
          if (redirect) {
            router.push('/main/home').then(() => router.push(redirect));
            removeKey(NOT_FOUNT_REDIRECT_STORAGE_KEY);
            removeKey(DEEP_LINK_REDIRECT_STORAGE_KEY);
            loading.value = false;
            return;
          }
          store.dispatch('profile/setProfileDetails')
            .then((data) => {
              const verifyPageCompleted = Boolean(data.professions?.length > 0 && data.profileDetails?.postal_code);
              const completeProfilePageCompleted = Boolean(store.getters['profile/getProfileDetails']?.first_name && store.getters['profile/getProfileDetails']?.last_name);

              if (completeProfilePageCompleted && verifyPageCompleted) {
                router.push('/');
              } else {
                openModal(verifyPageCompleted, completeProfilePageCompleted);
              }
            })
            .finally(() => {
              loading.value = false;
            });
        })
        .catch((error) => {
          loading.value = false;
          openErrorToast(error);
        })
        .finally(() => {
          auth.drivers.router.isRedirect = true;
        });
    };

    const openModal = async (verify, complete) => {
      const modal = await modalController.create({
        component: VerifyModal,
        componentProps: {
          verifyCompleted: verify,
          completeCompleted: complete,
        },
        backdropDismiss: false,
        keyboardClose: false,
        cssClass: 'verify-modal',
      });
      await modal.present();
    };


    onIonViewDidEnter(() => {
      termsConditionsAccepted.value = false;
    });

    onIonViewDidLeave(() => {
      canShowAlreadyLoggedCard.value = true;
    });


    return {
      mailOutline,
      lockClosedOutline,

      isLogged,
      loading,
      email,
      password,
      passwordRepeat,
      termsConditionsAccepted,
      canShowAlreadyLoggedCard,
      passwordErrors,
      passwordRules,
      passwordMatch,
      passwordValid,
      validatePasswordRules,
      openInBrowser,
      submitRegister,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  padding-top: 10vh;
}

.title {
  font-size: 50px;
}

.form {
  margin-top: 50px;
}

.submit-container {
  margin-top: 40px;
}

.checkbox-container {
  --padding-start: 5px;

  .checkbox {
    margin-right: 10px;
    --border-color: var(--ion-color-primary-contrast);
  }
}

.tag.is-incorrect{
  background-color: #282828;
  color: #FFF;
}


</style>
